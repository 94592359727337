<template>
  <div>
    <NuxtLayout>
      <NuxtPage />
    </NuxtLayout>
  </div>
</template>

<script setup lang="ts">
import { useViewStore } from "@/stores/ViewStore";

const viewStore = useViewStore();

useHead({
  title: "Voice Art Productions",
  link: [{ rel: "icon", type: "image/x-icon", href: "/favicon.ico" }],

  script: [
    {
      // PROD
      src: "https://www.googletagmanager.com/ns.html?id=GTM-WPXQFDVN",
      // DEV
      // src: "https://www.googletagmanager.com/ns.html?id=GTM-KLRCV7QL",
    },
    // {
    //   innerHTML: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    //   new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    //   j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    //   'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    //   })(window,document,'script','dataLayer','GTM-KLRCV7QL');`,
    //   type: "text/javascript",
    // },
    {
      src: "https://cdn.jsdelivr.net/npm/bootstrap@5.3.0-alpha1/dist/js/bootstrap.bundle.min.js",
      integrity:
        "sha384-w76AqPfDkMBDXo30jS1Sgez6pr3x5MlQ1ZAGC+nuZB+EYdgRZgiwxhTBTkF7CXvN",
      crossorigin: "anonymous",
    },
    // PROD - Cookie Banner
    {
      id: "Cookiebot",
      src: "https://consent.cookiebot.com/uc.js?cbid=f607075a-ea29-47f0-a6b7-c3c6ccfa29d6",
      "data-blockingmode": "auto",
      type: "text/javascript",
      async: true,
    },

    // DEV - Cookie Banner
    // {
    //   id: "Cookiebot",
    //   src: "https://consent.cookiebot.com/uc.js?cbid=ed63ade9-a6ca-4f0e-a881-05353cd54fec",
    //   "data-blockingmode": "auto",
    //   type: "text/javascript",
    //   async: true,
    // },
  ],
  meta: [
    {
      name: "description",
      content: "",
    },
    {
      name: "keywords",
      content:
        "Podcast produzieren lassen, Produktion Podcast, Schnitt, Cut, Post Produktion, Podcast aufnehmen, Podcast richtig machen, Host, Podcast erstellen, Podcast, Podcast Equipment, Podcast Produktion, Monetarisierung von Podcasts, Podcast-Marketing, Reichweite, Podcast producer, Podcast charts, Podcast selber machen, Heinlein & Weigert, Bildungspodcast, Persönlichkeitsentwicklung, Podcast schneiden, Professionelle Podcast-Produktion, Vermarktung für Podcasts, Podcast-Produktionsservice, Tipps Podcast, Podcasting, Beratung Podcast, Podcast Agentur, Hörspiel produzieren, Podcast starten, Podcast schneiden, Tonstudio für podcast, Podcaststudio, Hörspiel aufnehmen, Post Produktion, Produktion Meditation, Audio, Mikrofonierung Podcast, Wie podcast aufnehmen, Beste podcast firma, Podcast firma, Podcast anfangen, Werbung im podcast, Hörbuch, Hörbuch aufnehmen, Kosten podcast, Kosten hörspiel, Kosten Hörbuch, Hörspiel kinder, podcast kit, Kopfhörer podcast, Podcast Reichweite, podcast downloads, hörspiel selber machen, Hörspie schneiden lassen, Podcast audacity, Podcast garage band, Podcast studio, Podcast mikrofon, podcast tipps, Eigenen podcast, podcast kostenlos erstellen, Podcastschnitt, Podcast kosten, Eigenes Hörbuch erstellen, Hörbuch produzieren lassen, Hörbuch studio, Tonstudio, Podcast musik, Podcast Konzept, corporate podcast, Branded podcast, Laura Malina Seiler, Heinlein & Weigert, Saskia Niechzial Podcast, 4 Pfoten Podcast, Chris Bloom Podcast, Malia Verlag, Kostenrechner,Mastering Podcast, Musiktrenner, Audio Teaser, Shownotes, Kristin Graf Podcast, Meditation, Intro Podcast produzieren, Outro Podcast produzieren, Videopodcast",
    },
    { hid: "og-title", property: "og:title", content: "Voice Art Productions" },
    {
      hid: "og-desc",
      property: "og:description",
      content: "Creating Stories with Audio",
    },
    { hid: "og-type", property: "og:type", content: "website" },
    {
      hid: "og-image",
      property: "og:image",
      content:
        "https://voiceart-productions.com/_nuxt/vap-logo-preview.c222d73d.jpg",
    },
    {
      hid: "og-url",
      property: "og:url",
      content: "https://voiceart-productions.com",
    },
  ],
});

onMounted(async () => {
  // const runtimeConfig = useRuntimeConfig();
  // console.log("env variable: " + runtimeConfig.public.appEnv);
  // console.log("env variable: "+ runtimeConfig.public.testEnv);
  // console.log(process.env.APP_ENV)

  if (window.innerWidth < 992) {
    viewStore.isMobile = true;
  } else {
    viewStore.isMobile = false;
  }
});
</script>
